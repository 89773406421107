###
#
# @file EquityFrameworks - our animated presentation
#
###

import Cookies from 'js-cookie'

import { debounce } from 'throttle-debounce'

import { gsap } from './vendor/gsap/gsap-core.js'
import { TweenLite, CSSPlugin, ScrollTrigger, ScrollToPlugin, DrawSVGPlugin, MotionPathPlugin, MorphSVGPlugin, Draggable } from './vendor/gsap/all.js'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, DrawSVGPlugin, MotionPathPlugin, MorphSVGPlugin, Draggable)

window.gsap = gsap
window.ScrollTrigger = ScrollTrigger
window.ScrollToPlugin = ScrollToPlugin
window.DrawSVGPlugin = DrawSVGPlugin
window.MotionPathPlugin = MotionPathPlugin
window.MorphSVGPlugin = MorphSVGPlugin
window.Draggable = Draggable


class EquityFrameworks

  constructor: ()->
    __Purpose.register_barba_event_callback
      func: @init
      event: 'transitionCompleted'

    @base_duration = 0.3
    @defaults =
      duration: @base_duration
      transformOrigin: 'center'
      ease: 'Power4.easeInOut'
    @fdefaults = #for ferramentas
      duration: 1.0
      transformOrigin: 'center'
      ease: 'none'
    @timelines = []

    @tile_redirect_url = ''


    $(document)
    .on 'click', '[data-newsletter-toggle]', @toggle_newsletter
    .on 'click', '[data-offcanvas-nav-equity-frameworks]', @nav_equity_frameworks_toggle

    .on 'mouseenter', '#box-systemic-story', @tile_1_mouseenter
    .on 'mouseleave', '#box-systemic-story', @tile_1_mouseleave
    .on 'mouseenter', '#box-opportunities', @tile_2_mouseenter
    .on 'mouseleave', '#box-opportunities', @tile_2_mouseleave
    .on 'mouseenter', '#box-respectful', @tile_3_mouseenter
    .on 'mouseleave', '#box-respectful', @tile_3_mouseleave

    # .on 'touchstart', '#box-systemic-story', @tile_1_mouseenter
    # .on 'touchend', '#box-systemic-story', @tile_1_mouseleave
    # .on 'touchstart', '#box-opportunities', @tile_2_mouseenter
    # .on 'touchend', '#box-opportunities', @tile_2_mouseleave
    # .on 'touchstart', '#box-respectful', @tile_3_mouseenter
    # .on 'touchend', '#box-respectful', @tile_3_mouseleave

    .on 'mouseenter', '[data-globe-hover]', @globe_mouseenter
    .on 'mouseleave', '[data-globe-hover]', @globe_mouseleave
    .on 'click', '[data-scroll-to]', @nav_items_click
    .on 'click', '.btn-questions', @answers_container_toggle

    window.addEventListener("message", this.receiveMessage, false)


  init: ()=>
    $('body').removeClass('scroll-locked')
    
    tl.kill() for tl in @timelines # make sure we garbage collect timelines if the user moves off the page

    @timelines = []

    @header = $("header")

    landing = $("body").hasClass 'page-template-template-equity-frameworks-landing'
    systemic = $("body").hasClass 'page-template-template-equity-frameworks-systemic'
    opportunities = $("body").hasClass 'page-template-template-equity-frameworks-opportunities'
    representation = $("body").hasClass 'page-template-template-equity-frameworks-representation'

    # custom_ferramentas = $("#ferramentas-3").length > 0

    if landing or systemic or opportunities or representation
      @gate_main_nav()
      @check_hash_url()


    if systemic or opportunities or representation
      @header.addClass 'logo-symbol-only op0'
      @tile_hovers()

    if landing
      @init_landing()
      @tile_hovers()

    if systemic
      @keep_scrolling()
      @init_iceberg()

    if opportunities
      @bounce_unless_registered()
      @keep_scrolling()
      @init_opportunities()

    if representation
      @bounce_unless_registered()
      @keep_scrolling()
      @init_representation()

    # if custom_ferramentas
    #   @init_custom_ferramentas()


    $('body').removeClass 'op0' if gsap


  answers_container_toggle: (e)=>
    $(e.currentTarget).toggleClass 'active'
    $(e.currentTarget).parent('.questions-content').next('.answers-content').slideToggle()
      .promise().then ->
        ScrollTrigger.refresh( safe: true )

  bounce_unless_registered: ()=>
    #unless @is_registered()
      #location.href = '/equity-frameworks/#open-form'

  is_registered: ()=>
    Cookies.get 'purpose_equity_frameworks'

  check_hash_url: ()=>
    hash = window.location.hash
    if hash == '#open-form'
      @open_form()
      offset = $("#newsletter-form").offset().top - 100
      gsap.to(window, {duration: 2, scrollTo: {y: offset }})



  init_landing: ()=>
    @landing_hero()
    @create_simple_draw('#globe-care')
    @landing_phases_icon()


  init_iceberg: () =>
    @systemic_intro()
    @small_iceberg()
    @iceberg_examples()


  init_representation: () =>
    @representation_hero_parallax()
    @representation_calibrating()
    @representation_blocks()
    @iceberg_tile_hover()
    @race_tile_hover()
    @representation_tile_hover()

  init_opportunities: () =>
    @opportunities_hero()
    @opportunities_planning_action()
    @opportunities_pinned_content()
    @opportunities_are_you_ready()
    @iceberg_tile_hover()
    @race_tile_hover()
    @representation_tile_hover()


  # init_custom_ferramentas: () =>
  #   console.log("init_custom_ferramentas()")
  #   d = (el)->
  #     r = document.querySelector el
  #     console.log r
  #     r
  #   da = (el)->
  #     document.querySelectorAll el

  #   @ferramentas_btns d('#ferramentas-1 #botoes')
  #   @ferramentas_line_and_pop d('#ferramentas-1 #linha-1 path'), d('#ferramentas-1 #www-2')
  #   @ferramentas_text d('#ferramentas-1 #muitas-campanhas')
  #   @ferramentas_line_and_pop d('#ferramentas-1 #linha-2'), d('#ferramentas-1 #seta-2')
  #   @ferramentas_text d('#ferramentas-1 #O-melhor-formato-2')
  #   @ferramentas_line_and_pop d('#ferramentas-1 #linha-3'), d('#ferramentas-1 #jovens-velhos')
  #   @ferramentas_text d('#ferramentas-1 #Por-exemplo-2')
  #   @ferramentas_line_and_pop d('#ferramentas-1 #linha-4'), d('#ferramentas-1 #bolha')
  #   @ferramentas_text d('#ferramentas-1 #Aplicativos-de-mensagens')
  #   @ferramentas_line_and_pop d('#ferramentas-1 #linha-5'), d('#ferramentas-1 #celular')
  #   @ferramentas_text d('#ferramentas-1 #Se-um-conteudo')
  #   @ferramentas_line_and_pop d('#ferramentas-1 #linha-6'), da("#ferramentas-1 #circulo, #ferramentas-1 #seta")
  #   @ferramentas_text d('#ferramentas-1 #impulsionamento-2')
  #   @ferramentas_line d('#ferramentas-1 #linha-7')
  #   @ferramentas_line d('#ferramentas-1 #linha-7-2'), 'top 40%'
  #   @ferramentas_text d('#ferramentas-1 #Aposte-invista'), 'top 50%'

  #   @ferramentas_btns d('#ferramentas-2 #botoes'), 'top 50%'
  #   @ferramentas_line_and_pop d('#ferramentas-2 #Linha-1'), d('#ferramentas-2 #homen-mulher')
  #   @ferramentas_text d('#ferramentas-2 #e-a-hora-de-responder')
  #   @ferramentas_line_and_pop d('#ferramentas-2 #linha-2'), da('#ferramentas-2 #qual-perfil-2, #ferramentas-2 #homem-escada')
  #   @ferramentas_line_and_pop d('#ferramentas-2 #linha-3'), da('#ferramentas-2 #recorte-geografico-2, #ferramentas-2 #grafico')
  #   @ferramentas_line_and_pop d('#ferramentas-2 #linha-4'), da('#ferramentas-2 #grupo path'), 'top 60%', 0.1
  #   @ferramentas_text d('#ferramentas-2 #os-grupos')
  #   @ferramentas_line_and_pop d('#ferramentas-2 #linha-5'), da('#ferramentas-2 #lampada-seta  > path, #ferramentas-2 #lampada-seta  > g')
  #   @ferramentas_text d('#ferramentas-2 #hora-de-planejar')
  #   @ferramentas_line_and_pop d('#ferramentas-2 #linha-6'), d('#quebra-cabeca')
  #   @ferramentas_text d('#ferramentas-2 #e-preciso-elaborar')
  #   @ferramentas_line d('#ferramentas-2 #linha-7')
  #   @ferramentas_line d('#ferramentas-2 #linha-8'), 'top 60%'
  #   @ferramentas_text d('#ferramentas-2 #veja-alguns'), 'top 70%'
  #   @ferramentas_line_and_pop d('#ferramentas-2 #linha-9'), [d('#ferramentas-2 #campanhas-veiculadas'), d('#ferramentas-2 #solucoes-digitais')], 'top 50%', 1

  #   @ferramentas_btns d('#ferramentas-3 #botoes'), 'top 50%'
  #   @ferramentas_line_and_pop d('#ferramentas-3 #linha-1'), d('#ferramentas-3 #balao')
  #   @ferramentas_text d('#ferramentas-3 #O-feeling-importante')
  #   @ferramentas_line d('#ferramentas-3 #linha-2')
  #   @ferramentas_text d('#ferramentas-3 #Pesquisas-2')
  #   @ferramentas_pop da('#ferramentas-3 #cifrao text, #ferramentas-3 #cifrao g path'), 'top 50%', 0.1
  #   @ferramentas_line_and_pop d('#ferramentas-3 #linha-3'), da('#ferramentas-3 #grafico path')
  #   @ferramentas_text d('#ferramentas-3 #para-completar-2')
  #   @ferramentas_line_and_pop d('#ferramentas-3 #linha-4'), d('#ferramentas-3 #alvo')
  #   @ferramentas_text d('#ferramentas-3 #basear-campanhas-2')
  #   @ferramentas_line_and_pop d('#ferramentas-3 #linha-5'), d('#ferramentas-3 #megafone')
  #   @ferramentas_text d('#ferramentas-3 #pesquisas-revelam-2'), 'top 50%'
  #   @ferramentas_line_and_pop d('#ferramentas-3 #linha-6'), d('#ferramentas-3 #aviao')
  #   @ferramentas_text d('#ferramentas-3 #partir-de-uma-analise'), 'top 50%'

  brazil_form_submit: =>
    $(".pre-thank-you").slideUp()
    $(".thank-you").slideDown()

  ferramentas_line: (el, start = 'top 60%') =>
    el.style.opacity = 0
    @timeline(
      defaults: @fdefaults
      scrollTrigger:
        trigger: el
        start: start
      ).fromTo(el,  {drawSVG: "0%"}, {drawSVG: "100%"}, 0)
        .set(el, {opacity: 1}, 0.05)

  ferramentas_line_and_pop: (el, second, start = 'top 60%', stagger = 0.3) =>
    el.style.opacity = 0
    @timeline(
      defaults: @fdefaults
      scrollTrigger:
        trigger: el
        start: start
      ).fromTo(el,  {drawSVG: "0%"}, {drawSVG: "100%"}, 0)
        .set(el, {opacity: 1}, 0.05)
      .add(
        @timeline(defaults: @fdefaults)
        .fromTo(second, {scale: 0.4}, {scale: 1,  ease: "elastic.out(1, 0.5)", stagger: stagger}, 0)
        .fromTo(second, {opacity: 0}, {opacity: 1, duration: 0.25, stagger: stagger}, 0)
      )

  ferramentas_text: (el, start = 'top 60%') =>
    @timeline(
      defaults: @fdefaults
      scrollTrigger:
        trigger: el
        start: start
      ).from(el, {y: '+=15px', scale: 0.8, opacity: 0, duration: 0.5}, 0)

  ferramentas_pop: (el, start = 'top 60%', stagger = 0) =>
    @timeline(
      defaults: @fdefaults
      scrollTrigger:
        trigger: el
        start: start
        end: 'bottom 60%'
      )
      .fromTo(el, {scale: 0.4}, {stagger: stagger, scale: 1,  ease: "elastic.out(1, 0.5)"}, 0)
      .fromTo(el, {opacity: 0}, {stagger: stagger, opacity: 1, duration: 0.25}, 0)

  ferramentas_btns: (el, start = 'top 50%')=>
    btns = el.querySelectorAll('g')
    @timeline(
      defaults:
        duration: 1.0
        transformOrigin: 'center'
        ease: 'none'
      scrollTrigger:
        trigger: el
        start: start
        end: 'bottom 60%'
      )
      .fromTo(btns, {scale: 0.4}, {scale: 1,  ease: "elastic.out(1, 0.5)", stagger: 0.25}, 0)
      .fromTo(btns, {opacity: 0}, {opacity: 1, duration: 0.25, stagger: 0.25}, 0)

  ferramentas_scroll: (id)->
    top = $(id).position().top
    gsap.to(window, {duration: 2, scrollTo: top - $(window).height()/4 });

  ##
  # Creates a simple drawSVG animation, assumes the SVG in question has a single path element to animate,
  # but can be overridden with the path_selector options parameter
  create_simple_draw: (trigger, opts) =>
    opts = Object.assign {
      path_selector: 'path'
      scrub: true
      topOffset: ""
      duration: 30
    }, opts
    @timeline(
      defaults:
        transformOrigin: 'center'
        ease: 'none'
      scrollTrigger:
        trigger: trigger
        start: "top#{opts.topOffset} center"
        end: "top center-=#{opts.duration}px"
        scrub: opts.scrub)
      .fromTo("#{trigger} #{opts.path_selector}", { drawSVG: "0%" }, {drawSVG: "100%"}, 0)

  create_staggered_draw: (trigger, path_selector = 'path', duration = 100) =>
    @timeline(
      defaults:
        duration: @base_duration
        transformOrigin: 'center'
        ease: 'none'
      scrollTrigger:
        trigger: trigger
        start: 'top center'
        end: "top center-=#{duration}px"
        scrub: true
    ).fromTo("#{trigger} #{path_selector}", {drawSVG: "0%"}, {stagger: 0.1, duration: 0.1, drawSVG: "100%"})

  globe_mouseenter: (e)=>
    index = $(e.currentTarget).data('globeHover')
    @globe_hovers[index].timeScale(1).play(0)

  globe_mouseleave: (e)=>
    index = $(e.currentTarget).data('globeHover')
    @globe_hovers[index].timeScale(-1)

  iceberg_examples: =>
    @timeline(
      defaults: @defaults
      scrollTrigger:
        trigger: '.equity-content.examples'
        start: 'top bottom'
        end: 'bottom top'
        scrub: true)
      .fromTo('.background', {y: 0}, {ease: "none", y: 1500})

    @create_simple_draw('#police-header-globe',
      topOffset: "-=50px"
      duration: "1"
    )

    for i in [1..4]
      @create_staggered_draw("[data-example-arrow='#{i}']", 'path', 50)

  iceberg_globe_hover: (selectors)=>
    svg_selects = ("#{selector} svg line, #{selector} svg path" for selector in selectors).join ", "
    span_selects = ("#{selector} span" for selector in selectors).join ", "
    @timeline()
    .fromTo(svg_selects, {drawSVG: "0%"}, {stagger: .15, duration: .15, drawSVG: "100%"}, 0)
    .fromTo(span_selects, {opacity: 0}, {duration: .15, opacity: 1}, @base_duration)
    .pause()

  iceberg_tile_hover: ()=>
    @tile_1_hover_tl = @timeline(defaults: @defaults)
      .to('#box-systemic-story svg', {bottom: 0})
      .pause()


  nav_equity_frameworks_toggle: (e) ->
    e.preventDefault()
    $('body').toggleClass 'equity-nav-expanded'

  nav_items_click: (e)->
    e.preventDefault()
    target = $(e.currentTarget).data 'scroll-to'
    offset = $(e.currentTarget).data 'offset' || 0
    gsap.to(window, {duration: 2, scrollTo: {y: "##{target}", offsetY: offset }})


  newsletter_equity_form_submit: ()=>
    Cookies.set 'purpose_equity_frameworks', 1
    location.href = @tile_redirect_url


  gate_main_nav: ()=>
    return if @is_registered()
    nav_anchors = ["systemic-story", "opportunities", "respectful"]
    navs = document.querySelectorAll('.equity-nav')

    navs.forEach (el, i) =>
      nav_items = document.querySelectorAll("##{el.id} ul li a")
      nav_items.forEach (el, i)=>
        if i != 0
          el.href = "#"
          el.setAttribute "data-scroll-to", "box-#{nav_anchors[i]}"


  landing_phases_icon: (e)=>
    MorphSVGPlugin.convertToPath('#icon-phase-1-svg line, #icon-phase-1-svg polygon')

    @timeline(
      defaults:
        transformOrigin: 'center'
        ease: 'none'
      scrollTrigger:
        trigger: '#phases-content'
        start: 'top center'
        endTrigger: '#shape-4'
        end: 'top center'
        pin: '#icon-phases'
        scrub: true)


    @icon_shape_1_tl = @timeline(
      defaults:
        transformOrigin: 'center'
        ease: 'none'
      scrollTrigger:
        trigger: '#shape-1'
        start: 'bottom center'
        endTrigger: '#shape-2'
        end: 'top center'
        scrub: true)
      .to('#icon-phase-1-svg #recta', {morphSVG: '#icon-phase-1-svg #rectb'}, 0)
      .to('#icon-phase-1-svg #line-1a', {morphSVG: '#icon-phase-1-svg #line-1b'}, 0)
      .to('#icon-phase-1-svg #line-2a', {morphSVG: '#icon-phase-1-svg #line-2b'}, 0)
      .to('#icon-phase-1-svg #line-3a', {morphSVG: '#icon-phase-1-svg #line-3b'}, 0)

    @icon_shape_1_tl = @timeline(
      defaults:
        transformOrigin: 'center'
        ease: 'none'
      scrollTrigger:
        trigger: '#shape-2'
        start: 'bottom center'
        endTrigger: '#shape-3'
        end: 'top center'
        scrub: true)
      # .to('#icon-phase-1-svg #recta', {morphSVG: '#icon-phase-1-svg #rectc'}, 0)
      .to('#icon-phase-1-svg #line-1a', {morphSVG: '#icon-phase-1-svg #line-1c', rotate: '+=45deg'}, 0)
      .to('#icon-phase-1-svg #line-2a', {morphSVG: '#icon-phase-1-svg #line-2c', rotate: '+=45deg'}, 0)
      .to('#icon-phase-1-svg #line-3a', {morphSVG: '#icon-phase-1-svg #line-3c', rotate: '+=45deg'}, 0)

    @icon_shape_1_tl = @timeline(
      defaults:
        transformOrigin: 'center'
        ease: 'none'
      scrollTrigger:
        trigger: '#shape-3'
        start: 'bottom center'
        endTrigger: '#shape-4'
        end: 'top center'
        scrub: true)
      .to('#icon-phase-1-svg #recta', {morphSVG: '#icon-phase-1-svg #rect0'}, 0)
      .to('#icon-phase-1-svg #line-1a', {morphSVG: '#icon-phase-1-svg #line-1d', rotate: '-=45deg'}, 0)
      .to('#icon-phase-1-svg #line-2a', {morphSVG: '#icon-phase-1-svg #line-2d', rotate: '-=45deg'}, 0)
      .to('#icon-phase-1-svg #line-3a', {morphSVG: '#icon-phase-1-svg #line-3d', rotate: '-=45deg'}, 0)

  opportunities_are_you_ready: ()=>
    @create_staggered_draw('#globe-ready')
    @create_staggered_draw('#ready-to-act-underline-double')
    @create_staggered_draw('#ico-quote-arrow')


  opportunities_pinned_content: ()=>

    ScrollTrigger.matchMedia({
      # desktop
      "(min-width: 768px)": () =>
        @opportunities_nav = @timeline(
          defaults: @defaults
          scrollTrigger:
            trigger: '#opportunities-trigger-content'
            start: 'top+=300 center'
            end: 'bottom center+=500'
            pin: '.opportunities-nav'
            pinSpacing: false)
          .pause()

      # mobile
      "(max-width: 799px)": ->
    })

    opportunity_sections = document.querySelectorAll('.equity-opportunity-section')
    opportunity_sections.forEach (section, index)=>
      id = index + 1
      next_id = if id is 5 then false else id + 1
      opacity_initial = .1

      ScrollTrigger.matchMedia({

        # desktop
        "(min-width: 1024px)": () =>
          # Label "Oportunity to act" pinned
          opportunity_to_act_tl = @timeline(
            defaults: @defaults
            scrollTrigger:
              trigger: "#section-#{id}-trigger-2"
              start: 'top -100px'
              end: 'bottom center-=280px'
              pin: "#section-#{id}-trigger-2 .opportunities-to-act"
              scrub: true
              pinSpacing: false)

        # tablet-up
        "(min-width: 768px) and (max-width: 1023px)": () =>
          # Label "Oportunity to act" pinned
          opportunity_to_act_tl = @timeline(
            defaults: @defaults
            scrollTrigger:
              trigger: "#section-#{id}-trigger-2"
              start: 'top +100px'
              end: 'bottom +200px'
              pin: "#section-#{id}-trigger-2 .opportunities-to-act"
              scrub: true
              pinSpacing: false)

        "(min-width: 768px)": () =>
          # Set initial state for images (prevents odd behavior)
          gsap.to("#image-section-#{id}", {opacity: 0})

          opportunity_section_tl = @timeline(
            defaults: @defaults
            scrollTrigger:
              trigger: "#section-#{id}"
              start: 'top top'
              end: 'bottom center-=100px'
              pin: "#image-section-#{id}"
              scrub: true
              pinSpacing: false)
            .fromTo("[data-nav-item='#{id}']", {opacity: opacity_initial},
              ease: 'none'
              duration: 0.1
              opacity: 1
              scrollTrigger:
                trigger: "#section-#{id}-trigger-1"
                start: 'top center'
                end: 'bottom center'
                scrub: true
            , 0)
            .fromTo("[data-nav-item='#{id}'] svg path", { drawSVG: "0%" },
              duration: 0.1
              drawSVG: "100%"
              scrollTrigger:
                trigger: "#section-#{id}-trigger-1"
                start: 'top center+=500px'
                end: 'bottom center+=300px'
                scrub: true
            , 0)

            if next_id
              opportunity_section_tl
                .fromTo("[data-nav-item='#{id}']", {opacity: 1},
                  ease: 'none'
                  duration: 0.1
                  opacity: opacity_initial
                  immediateRender: false
                  scrollTrigger:
                    trigger: "#section-#{next_id}-trigger-1"
                    start: 'top bottom'
                    end: 'top top'
                    scrub: true
                , 0)
                .fromTo("[data-nav-item='#{id}'] svg path", {drawSVG: "100%"},
                  duration: 0.1
                  drawSVG: "0%"
                  immediateRender: false
                  scrollTrigger:
                    trigger: "#section-#{next_id}-trigger-1"
                    start: 'top bottom+=500px'
                    end: 'top top'
                    scrub: true
                , 0)
                .to("[data-nav-item]:not([data-nav-item='#{id}'])", {
                  duration: 0.1
                  y: "-=#{$("[data-nav-item=#{next_id}] span").data 'rac-y'}px"
                  immediateRender: false
                  scrollTrigger:
                    trigger: "#section-#{next_id}-trigger-1"
                    start: 'top bottom'
                    end: 'top top'
                    scrub: true
                }, 0)
                .to("[data-nav-item='#{id}']", {
                  duration: 0.1
                  y: "-=#{$("[data-nav-item=#{next_id}] span").data 'rac-y'}"
                  immediateRender: false
                  scrollTrigger:
                    trigger: "#section-#{next_id}-trigger-1"
                    start: 'top center-=100px'
                    end: 'top top'
                    scrub: true
                }, 0)

            # Image section animation
            opportunity_section_tl.fromTo("#image-section-#{id}", {opacity: 0},
              ease: 'none'
              opacity: 1
              duration: .2
              scrollTrigger:
                trigger: "#section-#{id}-trigger-1"
                start: 'top center'
                scrub: true
            , 0)
            .fromTo("#image-section-#{id}", {y: '0'},
              y: '-=20'
              scrollTrigger:
                trigger: "#section-#{id}-trigger-1"
                start: 'center center'
                scrub: true
            , 0)
            .fromTo("#image-section-#{id}", {opacity: 1},
              ease: 'none'
              opacity: 0
              scrollTrigger:
                trigger: "#section-#{id}-trigger-2"
                start: 'center center+=500px'
                scrub: true
            , 0)
            .pause()

        # mobile
        "(max-width: 767px)": () =>

          opportunities_nav = @timeline(defaults: {ease: 'none', transformOrigin: 'center'})
            # .to("#section-#{id} .mobile-section-nav .image-section",
            #   y: "-=55px"
            #   scrollTrigger:
            #     trigger: "#section-#{id} .opportunities-nav"
            #     start: 'top center'
            #     scrub: true
            # , 0)
            .to("#section-#{id} .opportunities-nav ul",
              y: "+=#{$('body').width() / 1.85}px"
              scrollTrigger:
                trigger: "#section-#{id} .opportunities-nav"
                start: 'top center'
                scrub: true
            , 0)
            .to("#section-#{id} .opportunities-nav ul li.active",
              opacity: 1
              duration: 0.1
              scrollTrigger:
                trigger: "#section-#{id} .opportunities-nav"
                start: 'top center'
                scrub: true
            , 0)
            .to("#section-#{id} .opportunities-nav ul li:not(.active)",
              opacity: 0
              scrollTrigger:
                trigger: "#section-#{id} .opportunities-nav"
                start: 'top center-=150px'
                scrub: true
            , 0)
            .to("#section-#{id} .opportunities-nav ul li:not(.active)",
              height: 0
              scrollTrigger:
                trigger: "#section-#{id} .opportunities-nav"
                start: 'bottom center-=150px'
                scrub: true
            , 1)

            .pause()

          fixed_nav_height = "54px"
          pinned_content = @timeline(
            ease: 'none'
            transformOrigin: 'center'
            scrollTrigger:
              trigger: "#section-#{id}-trigger-1"
              endTrigger: "#section-#{id}-trigger-2-mobile"
              start: "top top+=#{fixed_nav_height}"
              end: "top top+=#{fixed_nav_height}"
              scrub: true
              pin: "#section-#{id} .pinned-mobile-content"
              pinSpacing: false)
            .to("#section-#{id} .opportunities-nav ul li.active svg",
              opacity: 0
              scrollTrigger:
                trigger: "#section-#{id}-trigger-1"
                start: 'top center'
                end: 'bottom center'
                scrub: true
            , 0)

          # Label "Oportunity to act" pinned
          opportunity_to_act_tl = @timeline(
            defaults: @defaults
            scrollTrigger:
              trigger: "#section-#{id}-trigger-2"
              start: 'top center-=300px'
              end: 'bottom center-=180px'
              pin: "#section-#{id}-trigger-2 .opportunities-to-act"
              scrub: true
              pinSpacing: false)
      })


  landing_hero: ()=>
    @hero_arrows_tl = @timeline(defaults: @defaults)
      .fromTo("#landing-hero-arrows path", { drawSVG: "0%" }, {duration: 1, drawSVG: "100%", delay: .5 }, 0)


  opportunities_hero: ()=>
    MorphSVGPlugin.convertToPath(".equity-hero.opportunities svg line, .equity-hero.opportunities svg polygon")

    hero_icons = document.querySelectorAll('.equity-hero--icon-opportunities')
    hero_icons.forEach (icon)=>
      @hero_icon_tl = @timeline(defaults: @defaults)
      .fromTo(".#{icon.classList[1]} path:not(.globe)", { drawSVG: "0%" }, {duration: 1, drawSVG: "100%", delay: 0 }, 0)
      .fromTo(".#{icon.classList[1]} path.globe", { drawSVG: "0%" }, {duration: 1, drawSVG: "100%", delay: 0 }, 1)

    @hero_parallax_tl = @timeline(defaults: @defaults)
      .to('.equity-hero--icon-opportunities', {yPercent: 60, ease: 'none', scrollTrigger: { trigger: "#hero-opportunities", scrub: true}}, 0)
      .pause()

  opportunities_planning_action: ()=>
    MorphSVGPlugin.convertToPath("#planning-action-underline-irregular polyline")

    @create_staggered_draw('#planning-action-globe')
    @create_staggered_draw('#planning-action-underline-simple')
    @create_staggered_draw('#planning-action-underline-irregular')
    @create_staggered_draw('#planning-action-arrows')
    # @create_staggered_draw('#planning-action-underline-double')

  race_tile_hover: ()=>
    @tile_2_hover_tl = @timeline(defaults: @defaults)
      .fromTo('#landing-box-02-shape', {scale: 1, bottom: -10}, {duration: 0.10, scale: 0.5, bottom: 60})
      .fromTo('#landing-box-02-shape-hover path', { drawSVG: "0%" }, {duration: @base_duration, drawSVG: "100%" }, 0.3)
      .pause()

  receiveMessage: (event)=>
    if event.origin is window.location.origin and event?.data?.type is "equity_frameworks_submit"
      @newsletter_equity_form_submit()
    if event.origin is window.location.origin and event?.data?.type is "brazil_submit"
      @brazil_form_submit()

  representation_tile_hover: ()=>
    @tile_3_hover_tl = @timeline(defaults: @defaults)
      .fromTo('#landing-box-03-shape', {opacity: 1}, {opacity: 0})
      .fromTo('#landing-box-03-shape-hover', { opacity: 0 }, {opacity: 1}, 0)
      .pause()

  representation_blocks: ()=>
    representation_blocks = document.querySelectorAll('.representation-content')

    representation_blocks.forEach (section)=>
      section_id = section.id

      ScrollTrigger.matchMedia({

        # desktop
        # "(min-width: 1024px)": () =>
        #   console.log("testing!!!")
        #   representation_tl = @timeline(
        #     defaults: @defaults
        #     scrollTrigger:
        #       trigger: section
        #       start: 'top center'
        #       end: "top center"
        #       pin: "##{section_id} .representation-pinned"
        #       pinSpacing: false)
        #   .to("##{section_id} .equity-representation-label div", {ease: 'none', y: 0, scrollTrigger: { trigger: "##{section_id} .representation-pinned", scrub: false}}, 0)
        #   .pause()

        # tablet & desktop
        "(min-width: 768px)": () =>
          distance = if section_id == 'harmful-representation' then '530' else '592'
          representation_tl = @timeline(
            defaults: @defaults
            scrollTrigger:
              trigger: section
              start: 'top top'
              end: "bottom top+=#{distance}"
              pin: "##{section_id} .representation-pinned"
              pinSpacing: false)
          .to("##{section_id} .equity-representation-label div", {ease: 'none', y: 0}, 0)
          .pause()

        # mobile
        "(max-width: 767px)": () =>
          representation_tl = @timeline(
            defaults: @defaults
            scrollTrigger:
              trigger: section
              start: 'top top'
              end: 'bottom center'
              pin: "##{section_id} .representation-pinned"
              pinSpacing: false)
            .to("##{section_id} .equity-representation-label div", {ease: 'none', y: 0}, 0)
            .pause()
      })

      blocks = document.querySelectorAll("##{section_id} .verbs-block")
      adjectives = document.querySelectorAll("##{section_id} .verbs-block .equity-description--headline")

      blocks.forEach (el)=>
        trigger = $(el).attr 'id'
        cat = $(el).attr('class').split(/\s+/)
        subcat = $(el).attr('id').split('-')

        ScrollTrigger.matchMedia({
          # tablet & desktop
          "(min-width: 768px)": () =>
            blocks_tl = @timeline(
              defaults: @defaults
              scrollTrigger:
                trigger: "##{trigger}"
                start: 'top center-=200'
                end: "top center-=250"
                scrub: true)
            .to(".ico-verbs", {duration: 0, ease: 'none', opacity: .3}, 0) # reset opacity in all icons
            .fromTo("##{cat[1]}-ico-#{subcat[2]}", {opacity: .3}, {duration: 0.1, opacity: 1, ease: "none" }, 0) # icon
            .fromTo("##{cat[1]}-representation-verbs", { opacity: 1 }, {ease: "none", duration: .1, y: "-=36"}, 0) # verb
            .pause()

          # mobile
          "(max-width: 767px)": () =>
            blocks_tl = @timeline(
              defaults: @defaults
              scrollTrigger:
                trigger: "##{trigger}"
                start: 'top center'
                end: "top center-=100"
                scrub: true)
            .to(".ico-verbs", {duration: 0, ease: 'none', opacity: .3}, 0) # reset opacity in all icons
            .fromTo("##{cat[1]}-ico-#{subcat[2]}", {opacity: .3}, {duration: 0.1, opacity: 1, ease: "none" }, 0) # icon
            .fromTo("##{cat[1]}-representation-verbs", { opacity: 1 }, {ease: "none", duration: .1, y: "-=36"}, 0) # verb
            .pause()
        })


      adjectives.forEach (el) =>
        trigger = $(el).attr 'id'

        ScrollTrigger.matchMedia({
          # tablet & desktop
          "(min-width: 768px)": () =>
            adjectives_tl = @timeline(
              defaults: @defaults
              scrollTrigger:
                trigger: "##{trigger}"
                start: 'top center+=150')
            .to("##{trigger} span", {ease: 'none', width: '100%'}, 0)
            .pause()

          # mobile
          "(max-width: 767px)": () =>
            adjectives_tl = @timeline(
              defaults: @defaults
              scrollTrigger:
                trigger: "##{trigger}"
                start: 'top center')
            .to("##{trigger} span", {ease: 'none', width: '100%'}, 0)
            .pause()

        })


  representation_hero_parallax: ()=>
    @hero_representation_tl = @timeline(defaults: @defaults)
      .to('.parallax-1', {yPercent: -30, ease: 'none', scrollTrigger: { trigger: "#hero-representation", scrub: true}}, 0)
      .to('.parallax-2', {yPercent: 30, ease: 'none', scrollTrigger: { trigger: ".icon-arrows", scrub: true}}, 0)
      .pause()

  representation_calibrating: ()=>
    # Invert path direction first
    gsap.set('#ico-calibrating-braket-top', { scaleX: -1, transformOrigin: "center center" })
    @create_simple_draw('#ico-calibrating-braket-top',
      topOffset: '-=300px'
    )
    @create_simple_draw('#ico-calibrating-braket-bottom',
      topOffset: '-=800px'
    )
    @bracket_arrow = @timeline(defaults: @defaults)
      .fromTo('#ico-calibrating-braket-arrow', {opacity: 0}, {opacity: 1, scrollTrigger: { trigger: ".conversational-headline", scrub: true}}, 0)
      .pause()

  small_iceberg: =>
    window.gsap = gsap # for debugging

    ScrollTrigger.matchMedia({

      # desktop
      "(min-width: 1024px)": () =>
        @timeline(
          defaults: @defaults
          scrollTrigger:
            trigger: '.iceberg-background.real'
            start: 'top bottom'
            onEnter: ->
              $('.iceberg-background.mock').addClass('hide')
            onLeaveBack: ->
              $('.iceberg-background.mock').removeClass('hide')
            scrub: true
          )

      # mobile
      "(max-width: 1023px)": () =>
        @timeline(
          defaults: @defaults
          scrollTrigger:
            trigger: '.iceberg-background.real'
            start: 'top+=75px bottom'
            onEnter: ->
              $('.iceberg-background.mock').addClass('hide')
            onLeaveBack: ->
              $('.iceberg-background.mock').removeClass('hide')
            scrub: true
          )
    })

    @globe_hovers = [
      @iceberg_globe_hover(['#section-1-globe-1', '#section-1-globe-2'])
      @iceberg_globe_hover(['#section-2-globe-1', '#section-2-globe-2', '#section-2-globe-3'])
      @iceberg_globe_hover(['#section-3-globe-1', '#section-3-globe-2', '#section-3-globe-3'])
      @iceberg_globe_hover(['#section-4-globe-1', '#section-4-globe-2'])
    ]

  systemic_intro: =>
    gsap.fromTo('#systemic-line-1 path', {opacity: 1, drawSVG: "0%"}, {delay: 1, opacity: 1, duration: @base_duration, drawSVG: "100%"})
    @create_staggered_draw('i.img-3-arrows')
    @create_staggered_draw('#conversational-underline-double')
    @create_staggered_draw('#conversational-underline-simple')

    @timeline(
      defaults: @defaults
      scrollTrigger:
        trigger: '.box-conversational-intro'
        start: 'top bottom'
        end: 'bottom center'
        scrub: true
      ).fromTo('svg#bracket polyline',  {drawSVG: "0%"},    {duration: 0.3, drawSVG: "100%"}, 0)
      .fromTo('.bracket-mask',          {opacity: 0},       {duration: 0.001, opacity: 1},      0)
      .fromTo('svg#bracket polyline',   {drawSVG: "100%"},  {immediateRender: false, duration: 0.3, drawSVG: "0%"},   0.9)
      .fromTo('.bracket-mask',          {opacity: 1},       {duration: 0.1, opacity: 0},      0.9)


  tile_1_mouseenter: =>
    @tile_1_hover_tl
      .timeScale(1)
      .play(0)

  tile_1_mouseleave: =>
    @tile_1_hover_tl
      .timeScale(-1)

  tile_2_mouseenter: =>
    @tile_2_hover_tl
      .timeScale(1)
      .play(0)

  tile_2_mouseleave: =>
    @tile_2_hover_tl
      .timeScale(-1)

  tile_3_mouseenter: =>
    @tile_3_hover_tl
      .timeScale(1)
      .play(0)

  tile_3_mouseleave: =>
    @tile_3_hover_tl
      .timeScale(-1)

  tile_hovers: =>
    ScrollTrigger.matchMedia({

      # desktop
      "(min-width: 768px)": () =>
        @iceberg_tile_hover()
        @race_tile_hover()
        @representation_tile_hover()

      # mobile
      "(max-width: 767px)": () =>

        @tile_1_animation_tl = @timeline(
          defaults: @defaults
          scrollTrigger:
            trigger: "#box-systemic-story"
            start: 'top center'
            end: 'bottom center'
            onEnter: ->
              $('#box-systemic-story').addClass('active')
            onLeaveBack: ->
              $('#box-systemic-story').removeClass('active')
            scrub: true
          )
          .to('#box-systemic-story svg', {bottom: 0})
          .pause()

        @tile_2_animation_tl = @timeline(
          defaults: @defaults
          scrollTrigger:
            trigger: "#box-opportunities"
            start: 'top center'
            end: 'bottom center'
            onEnter: ->
              $('#box-opportunities').addClass('active')
            onLeaveBack: ->
              $('#box-opportunities').removeClass('active')
            scrub: true
          )
          .fromTo('#landing-box-02-shape', {scale: 1, bottom: -10}, {duration: 0.10, scale: 0.5, bottom: 60})
          .fromTo('#landing-box-02-shape-hover path', { drawSVG: "0%" }, {duration: @base_duration, drawSVG: "100%" }, 0.3)
          .pause()

        @tile_3_animation_tl = @timeline(
          defaults: @defaults
          scrollTrigger:
            trigger: "#box-respectful"
            start: 'top center'
            end: 'bottom center'
            onEnter: ->
              $('#box-respectful').addClass('active')
            onLeaveBack: ->
              $('#box-respectful').removeClass('active')
            scrub: true
          )
          .fromTo('#landing-box-03-shape', {opacity: 1}, {opacity: 0})
          .fromTo('#landing-box-03-shape-hover', { opacity: 0 }, {opacity: 1}, 0)
          .pause()

    })


  keep_scrolling: () =>
    @scrolling_tl = @timeline(
      defaults: @defaults
    )
    .fromTo('.keep-scrolling', {opacity: 0, bottom: -50}, {duration: 1, opacity: 1, bottom: 20})

    @scrolling_toggle_tl = @timeline(
      defaults: @defaults
      scrollTrigger:
        trigger: '#keep-scrolling-toggle'
        start: 'top center'
        end: 'bottom center+=200'
        scrub: true
      )
      .fromTo('.keep-scrolling', {opacity: 1}, {duration: 0.7, opacity: 0})


  # create a GSAP timeline and keep track of it so we can play nice with turbolinks
  timeline: (params) =>
    tl = gsap.timeline(params)
    @timelines.push tl
    tl

  toggle_newsletter: (e) =>
    e.preventDefault()
    @tile_redirect_url = $(e.currentTarget).data 'newsletterToggle'
    id = $(e.currentTarget).attr 'id'

    if !@is_registered() && id != 'box-systemic-story'
      @open_form()
    else
      location.href = @tile_redirect_url


  open_form: ()=>
    $('.tile-boxes').toggleClass 'newsletter-visible'
    $('#phases-section').toggleClass 'low-z-index'
    $('.close-newsletter').fadeToggle()
    $('.equity-newsletter').fadeToggle()



window.equity_frameworks = new EquityFrameworks
